.no-scrollbars {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.no-scrollbars::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.carousel__dot {
  width: 10px;
  height: 14px;
  border-width: 1px;
  border-radius: 10px;
  margin-right: 5px;
  margin-left: 5px;
  background-color: white !important;
  border-color: white;
  border-style: solid;
  box-shadow: 0px 0px 3px rgba(0,0,0,1);
}
.carousel__dot--selected {
  background-color: #cf000d !important;
  box-shadow: none;
  padding: 8px;
}
#root{
  overflow-x: hidden;
  overflow-y: hidden;
}
.fade-enter {
  transform: translateX(100%);
}
.fade-enter-active {
  transform: translateX(0%);
  transition: transform 180ms;
}
.fade-exit { 
  transform: translateX(0%);
}
.fade-exit-active {
  transform: translateX(100%);
  transition: transform 250ms;
}

.cat-enter {
  opacity: 0;
  /* transform: translateY(-50%); */
}
.cat-enter-active {
  opacity: 1;
  /* transform: translateY(0); */
  transition: opacity 250ms;
}
.cat-exit {
  opacity: 1;
  /* transform: translateY(0); */
}
.cat-exit-active {
  opacity: 0;
  /* transform: translateY(-50%); */
  transition: opacity 250ms;
}

.pax-animate {
  animation: paxAni .2s;
  animation-timing-function: ease-out;
}
.bar-ani{
  animation: barAni .2s;
  animation-timing-function: ease-out;
}

body{
  background-color: grey;
}

@keyframes paxAni {
  0%  {
  transform: scale(1.4);
  }
  
  100%  {
  transform: scale(1);
  }
  
}

@keyframes barAni {
  0%  {
  transform: translateY(-50px);
  }
  
  100%  {
  transform: translateY(0);
  }
  
}