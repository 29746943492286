body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  display: flex;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root{
  max-width: 550px;
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham"), url(./fonts/Gotham/GOTHAM-BOOK.woff) format("woff"),
    url(./fonts/Gotham/GOTHAM-BOOK.woff2) format("woff2"),
    url(./fonts/Gotham/GOTHAM-BOOK.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(./fonts/Roboto/roboto-v30-latin-regular.ttf) format("truetype"),
  url(./fonts/Roboto/roboto-v30-latin-regular.woff2) format('woff2');
}

@font-face {
  font-family: "RobotoCondensed";
  src: local("RobotoCondensed"), url(./fonts/RobotoCondensed/RobotoCondensed-Regular.ttf) format("truetype")
  
}

@font-face {
  font-family: "Copperplate";
  src: local("Copperplate"), url(./fonts/CopperPlate/Copperplate.ttf) format("truetype")
  
}


